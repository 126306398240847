import Modal from 'antd/lib/modal/Modal';
import { useContext, useState } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { getManualRate, getRate } from '../../../api/NetworkingRates';
import AppContext from '../../../App.context';
import Button from '../../../components/inputs/Button';
import { formatToCurrency } from '../../../helpers/FormatHelper';
import { getResource } from '../../../helpers/ResourcesHelper';
import * as variables from '../../../styles/variables/variables.module.scss';

const QuoteBox = (props: any) => {
  const { showModal } = useContext(AppContext);
  const [isRunningTime, setIsRunningTime] = useState(false);
  const [quoteTime, setQuoteTime] = useState(0);
  const [customerRate, setCustomerRate] = useState(0);
  const [marketRate, setMarketRate] = useState(0);
  const [profit, setProfit] = useState(0);
  const [coverCustomerID, setCoverCustomerID] = useState(0);
  const [rateTerms, setRateTerms] = useState('');
  const [rateQuoteID, setRateQuoteID] = useState('');
  const [showQuoteModal, setShowQuoteModal] = useState(false);
  const [finalAmount, setFinalAmount] = useState(0);
  const [ratePrecision, setRatePrecision] = useState(2);

  //Order
  const [externalInterface, setExternalInterface] = useState('');
  const [externalInterfaceType, setExternalInterfaceType] = useState('');
  //

  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [confirmationTitle, setConfirmationTitle] = useState('');

  const onAcceptClick = () => {
    if (props.showConfirmation && props.confirmationMessage && props.confirmationTitle && customerRate > 0) {
      setConfirmationMessage(props.confirmationMessage);
      setConfirmationTitle(props.confirmationTitle);
      setShowModalConfirmation(true);
    }
    else {
      props.onAcceptClick(rateQuoteID, customerRate, marketRate, profit, coverCustomerID, externalInterface, externalInterfaceType, rateTerms);
      setIsRunningTime(false);
      setShowQuoteModal(false);
    }
  };

  const getAsyncManualRate = async (quoteID: string, tries: number) => {
    let status = 'inprocess';
    let jsonResponse = await getManualRate(quoteID);
    if (jsonResponse) {
      status = jsonResponse.RateActivityStatus;
      if (status === 'approved') {
        setCoverCustomerID(jsonResponse.CoverCustomerID);
        setMarketRate(jsonResponse.MarketRate);
        setProfit(jsonResponse.Profit);
        setQuoteTime(jsonResponse.ValidForSeconds);
        setCustomerRate(jsonResponse.CustomerRate);
        setRateTerms(jsonResponse.RateTerms);
        setRateQuoteID(jsonResponse.RateQuoteID);
        setIsRunningTime(true);
        calcAmount(jsonResponse.RateTerms, jsonResponse.CustomerRate, jsonResponse.RatePrecision);
      } else {
        if (status === 'rejected' || tries < 1) {
          setRateQuoteID('');
          showModal(getResource('Js_MessageError_TitleError'), getResource('Js_Message_NoManualRate'));
        } else {
          if (status === 'pending' || status === 'inprocess') {
            showModal(getResource('Js_MessageError_TitleMessage'), getResource('Js_Message_WaitingForManualRate'));
          } else {
            setRateQuoteID('');
            showModal(getResource('Js_MessageError_TitleError'), getResource('Js_Message_IssueRateAtThisTime'));
          }
        }
      }
    }
    return status;
  };

  const execManualRate = (tries: number, quoteID: string) => {
    let timeout = setTimeout(async () => {
      if (tries > 0 && customerRate === 0 && quoteID !== '') {
        tries = tries - 1;
        let activityStatus = await getAsyncManualRate(quoteID, tries);
        if (activityStatus === 'inprocess') {
          execManualRate(tries, quoteID);
        } else {
          clearTimeout(timeout);
        }
      }
    }, 10000);
  };

  const onQuoteActive = props.valueDate !== '' && props.sellCurrency !== '' && props.buyCurrency !== '' && props.amount > 0 && (props.dealType == 4 || (props.settlementMethod != null && props.settlementMethod.name));

  const onQuoteClick = () => {
    //setIsRunningTime(false);
    //setQuoteTime(0);
    //debugger;
    if (props.type === 'order' || props.type === 'approval') {
      if (props.validateFields != null) {
        if (!props.validateFields()) {
          showModal(getResource('Js_MessageError_TitleError'), getResource('Js_MessageError_FormValidationNFieldNHighlighted'));
          return;
        } else {
          if (props.atTheSameTimeToClickQuote)
            props.atTheSameTimeToClickQuote();
        }
      }
    } else {
      if (!onQuoteActive) {
        if (props.validateFields != null) { props.validateFields() }
        showModal(getResource('Js_MessageError_TitleError'), getResource('Js_MessageError_FormValidationNFieldNHighlighted'));
        return;
      }
    }

    let lockedSide;
    if (props.lockedSide === 'buy') {
      lockedSide = 'Buy';
    } else {
      lockedSide = 'Sell';
    }
    let userSession = JSON.parse(localStorage.getItem('UserSession')!);

    getRate(
      props.customerID,
      props.contactID,
      props.dealType,
      props.valueDate,
      props.forwardNearDate,
      props.sellCurrency,
      props.buyCurrency,
      props.amount,
      lockedSide,
      props.totalFeesAmount,
      userSession.OnlineInterface !== undefined ? userSession.OnlineInterface : '',
      userSession.OnlineInterfaceType !== undefined ? userSession.OnlineInterfaceType : ''
    ).then((rateResponse) => {
      console.log(rateResponse);
      if (rateResponse.CustomerRate != null) {
        if (rateResponse.CustomerRate === 0) {
          let waitForManualRate = localStorage.getItem('WaitForManualRate');

          if (waitForManualRate) {
            setRateQuoteID(rateResponse.RateQuoteID);
            showModal(getResource('Js_MessageError_TitleMessage'), getResource('Js_Message_WaitingForManualRate'));
            let tries = 3;
          } else {
            if (props.onError !== undefined) {
              props.onError();
            }
            showModal(getResource('Js_MessageError_TitleMessage'), getResource('Js_Message_IssueRateAtThisTime'));
          }
        } else {
          //orders
          setExternalInterface(rateResponse.ExternalInterface);
          setExternalInterfaceType(rateResponse.ExternalInterfaceType);
          //
          setCoverCustomerID(rateResponse.CoverCustomerID);
          setMarketRate(rateResponse.MarketRate);
          setProfit(rateResponse.Profit);
          setQuoteTime(rateResponse.ValidForSeconds === 0 ? 60 : rateResponse.ValidForSeconds);
          setCustomerRate(rateResponse.CustomerRate);
          setRateTerms(rateResponse.RateTerms);
          setRateQuoteID(rateResponse.RateQuoteID);
          setIsRunningTime(true);
          calcAmount(rateResponse.RateTerms, rateResponse.CustomerRate, rateResponse.RatePrecision)
          setRatePrecision(rateResponse.RatePrecision);
          setShowQuoteModal(true);
          if (props.mode === 'inline' && props.onTimeNoEnd) {
            props.onTimeNoEnd(
              rateResponse.RateQuoteID, rateResponse.CustomerRate, rateResponse.MarketRate, rateResponse.Profit,
              rateResponse.CoverCustomerID, rateResponse.ExternalInterface, rateResponse.ExternalInterfaceType, rateResponse.RateTerms);
          }

        }
      } else {
        if (props.onError !== undefined) {
          props.onError();
        }
        const userSession = JSON.parse(localStorage.getItem('UserSession')!);
        const onlineInterface: string = userSession.OnlineInterface;
        switch (onlineInterface) {
          case 'Currency Cloud - Auto Cover':
          case 'Currency Cloud - Pass Thru': {
            /*
              {
                "error_code":"rate_fetch_failed",
                "error_messages": {
                  "base": [
                    {
                      "code":"invalid_delivery_date",
                      "message":"Invalid Conversion Date - Provided date is in the past and thus is invalid.",
                      "params":{}}]}}
            */
            const response = JSON.parse(rateResponse.Message);
            const errorCode = response?.error_messages?.base[0]?.code ?? 'invalid_code';
            switch (errorCode) {
              case 'invalid_delivery_date': {
                showModal(getResource('Js_MessageError_TitleMessage'), 'The delivery date you have requested cannot be met for this currency pair.');
                break;
              }
              default: { // Error code not handled by switch
                showModal(getResource('Js_MessageError_TitleMessage'), 'Interface error.');
                console.log(rateResponse);
                break;
              }
            }
            break;
          }
          default: { // Online Interface not handled by switch
            showModal(getResource('Js_MessageError_TitleMessage'), rateResponse.Message);
            break;
          }
        }
      }
    });

    //setIsRunningTime(true);
  };

  const onNewTradeClick = () => {
    setIsRunningTime(false);
    setCustomerRate(0);
    setRateTerms('');
    setQuoteTime(0);
    setCoverCustomerID(0);
    setMarketRate(0);
    setProfit(0);
    props.onNewTradeClick();
  };

  const onCancelClick = () => {
    setIsRunningTime(false);
    setCustomerRate(0);
    setRateTerms('');
    setQuoteTime(0);
    setCoverCustomerID(0);
    setMarketRate(0);
    setProfit(0);
    setRatePrecision(2);
    setShowQuoteModal(false);
    props.onCancelQuote();
  };

  const calcAmount = (fixedRateTerms: string, fixedCustomerRate: number, ratePrecision: number) => {
    if (fixedRateTerms === 'I') {
      if (props.lockedSide === 'sell') {
        let newAmount = props.amount / fixedCustomerRate;
        props.amountCalc(newAmount);
        setFinalAmount(newAmount);
        if (props.onQuoteClick) {
          props.onQuoteClick(fixedCustomerRate, ratePrecision, newAmount);
        }
      } else {
        let newAmount = props.amount * fixedCustomerRate;
        props.amountCalc(newAmount);
        setFinalAmount(newAmount);
        if (props.onQuoteClick) {
          props.onQuoteClick(fixedCustomerRate, ratePrecision, newAmount);
        }
      }
    } else {
      if (props.lockedSide === 'sell') {
        let newAmount = props.amount * fixedCustomerRate;
        props.amountCalc(newAmount);
        setFinalAmount(newAmount);
        if (props.onQuoteClick) {
          props.onQuoteClick(fixedCustomerRate, ratePrecision, newAmount);
        }
      } else {
        let newAmount = props.amount / fixedCustomerRate;
        props.amountCalc(newAmount);
        setFinalAmount(newAmount);
        if (props.onQuoteClick) {
          props.onQuoteClick(fixedCustomerRate, ratePrecision, newAmount);
        }
      }
    }
  };

  const getDepositString = () => {
    if (props.lockedSide === 'sell') {
      let depositAmount = finalAmount * Number(props.depositRate);
      return getResource('Form.Label.Deposit') + ` (${props.depositRate * 100}%): ${formatToCurrency(depositAmount.toString(), props.sellCurrency)} ${props.sellCurrency}`;
    } else {
      let depositAmount = Number(props.amount) * Number(props.depositRate);
      return getResource('Form.Label.Deposit') + ` (${props.depositRate * 100}%): ${formatToCurrency(depositAmount.toString(), props.sellCurrency)} ${props.sellCurrency}`;
    }
  }

  const getAmountString = () => {
    if (props.lockedSide === 'sell') {
      return getResource('Label.WeBuy') + ` ${formatToCurrency(props.amount, props.buyCurrency)} ${props.buyCurrency} / ` + getResource('Label.pay') + ` ${formatToCurrency(finalAmount.toString(), props.sellCurrency)} ${props.sellCurrency}`;
    } else {
      return getResource('Label.WeSell') + ` ${props.sellCurrency} ${formatToCurrency(props.amount, props.sellCurrency)} / ` + getResource('Label.receive') + ` ${props.buyCurrency} ${formatToCurrency(finalAmount.toString(), props.buyCurrency)}`;
    }
  };

  const onCloseConfirmation = () => {
    setIsRunningTime(false);
    setCustomerRate(0);
    setRateTerms('');
    setQuoteTime(0);
    setCoverCustomerID(0);
    setMarketRate(0);
    setProfit(0);
    setRatePrecision(2);
    setShowQuoteModal(false);
    setShowModalConfirmation(false);
    props.onCancelQuote();
  }

  const onOkConfirmation = () => {
    if (isRunningTime) {
      props.onAcceptClick(rateQuoteID, customerRate, marketRate, profit, coverCustomerID, externalInterface, externalInterfaceType, rateTerms);
      setIsRunningTime(false);
      setShowQuoteModal(false);
      setShowModalConfirmation(false);
    }
    else {
      setShowModalConfirmation(false);
    }
  }

  return (
    <>
      {!isRunningTime && (
        <div className={props.mode === 'inline' ? 'col-md-6 col-xs-12 row midle-xs center-xs' : ''}>
          <Button type="primary" onClick={onQuoteClick}>
            {getResource('Quote')}
          </Button>
        </div>
      )}
      {/*!isRunningTime && onQuoteActive ? (
        <Button type="primary" onClick={onQuoteClick}>
          Quote
        </Button>
      ) : (
        !isRunningTime && <Button type="disable">Quote</Button>
      )*/}
      {showQuoteModal && props.mode === 'inline' && <>
        <div className="row center-xs">
          <div className="col-md-12 col-xs-12 row quote-box-parent">
            <div className="col-lg-12 col-xs-12">
              {isRunningTime && (
                <div className="row middle-xs quote-box">
                  <div className="col-xs-6">
                    <div className="row">
                      <span className="rate-pair">{rateTerms === 'D' ? `${props.sellCurrency} / ${props.buyCurrency}` : `${props.buyCurrency} / ${props.sellCurrency}`}</span>
                    </div>
                    <div className="row">
                      <span className="rate-text">{ratePrecision != null ? customerRate.toFixed(ratePrecision) : customerRate}</span>
                    </div>
                  </div>
                  <div className="col-xs-6">
                    <div className="row">
                      <span className="rate-countdown-label">{getResource('Label.RateTimesOut')}:</span>
                    </div>
                    <div className="row rate-countdown-container">
                      <CountdownCircleTimer
                        key={rateQuoteID}
                        isPlaying={isRunningTime}
                        size={75}
                        strokeWidth={10}
                        duration={quoteTime}
                        colors={[
                          [variables.greenColor, 0.33],
                          [variables.yellowColor, 0.33],
                          [variables.redColor, 1]
                        ]}
                        onComplete={() => {
                          setIsRunningTime(false);
                          onCancelClick();
                        }}
                      >
                        {({ remainingTime }) => {
                          return <div className="rate-countdown">{remainingTime}</div>;
                        }}
                      </CountdownCircleTimer>
                    </div>
                  </div>
                  <div className="col-xs-12">
                    <div className="row center-xs">
                      <div className="rate-amount-text">{getAmountString()}</div>
                    </div>
                  </div>
                  {props.showDeposit && <div className="col-xs-12">
                    <div className="row center-xs">
                      <div className="rate-amount-text">{getDepositString()}</div>
                    </div>
                  </div>}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row around-xs">
          <div className="col-xs-12 col-md-6">
            <div className="row quote-box-button-container">
              {isRunningTime ? (
                <Button type="primary" onClick={onCancelClick}>
                  {getResource('Btn_CancelQuote')}
                </Button>
              ) : (
                /*<Button type="disable">Accept</Button>*/ <div></div>
              )}
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="row quote-box-button-container">
              {isRunningTime ? (
                <Button type="active" onClick={onAcceptClick}>
                  {getResource('Accept')}
                </Button>
              ) : (
                /*<Button type="disable">Accept</Button>*/ <div></div>
              )}
            </div>
          </div>
        </div>
      </>}
      <Modal
        title={
          <div className="row">
            <div className="col-xs-4 subtitle-text-primary">{getResource('Quote')}</div>
          </div>
        }
        centered
        visible={showQuoteModal && props.mode === 'modal'}
        footer={null}
        width={'35%'}
        zIndex={1021}
        onCancel={onCancelClick}
        maskClosable={false}
      >
        <div className="row center-xs">
          <div className="col-md-10 col-xs-12 row quote-box-parent">
            <div className="col-lg-12 col-xs-12">
              {isRunningTime && (
                <div className="row middle-xs quote-box">
                  <div className="col-xs-6">
                    <div className="row">
                      <span className="rate-pair">{rateTerms === 'D' ? `${props.sellCurrency} / ${props.buyCurrency}` : `${props.buyCurrency} / ${props.sellCurrency}`}</span>
                    </div>
                    <div className="row">
                      <span className="rate-text">{ratePrecision != null ? customerRate.toFixed(ratePrecision) : customerRate}</span>
                    </div>
                  </div>
                  <div className="col-xs-6">
                    <div className="row">
                      <span className="rate-countdown-label">{getResource('Label.RateTimesOut')}:</span>
                    </div>
                    <div className="row rate-countdown-container">
                      <CountdownCircleTimer
                        key={rateQuoteID}
                        isPlaying={isRunningTime}
                        size={75}
                        strokeWidth={10}
                        duration={quoteTime}
                        colors={[
                          [variables.greenColor, 0.33],
                          [variables.yellowColor, 0.33],
                          [variables.redColor, 1]
                        ]}
                        onComplete={() => {
                          setIsRunningTime(false);
                          onCancelClick();
                        }}
                      >
                        {({ remainingTime }) => {
                          return <div className="rate-countdown">{remainingTime}</div>;
                        }}
                      </CountdownCircleTimer>
                    </div>
                  </div>
                  <div className="col-xs-12">
                    <div className="row center-xs">
                      <div className="rate-amount-text">{getAmountString()}</div>
                    </div>
                  </div>
                  {props.showDeposit && <div className="col-xs-12">
                    <div className="row center-xs">
                      <div className="rate-amount-text">{getDepositString()}</div>
                    </div>
                  </div>}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row around-xs" style={{ marginBottom: '10px' }}>
          <div className="col-xs-10 col-md-4">
            <div className="row quote-box-button-container">
              {isRunningTime ? (
                <Button type="secondary" onClick={onCancelClick}>
                  {getResource('Btn_CancelQuote')}
                </Button>
              ) : (
                /*<Button type="disable">Accept</Button>*/ <div></div>
              )}
            </div>
          </div>
          <div className="col-xs-10 col-md-4">
            <div className="row quote-box-button-container">
              {isRunningTime ? (
                <Button type="active" onClick={onAcceptClick}>
                  {getResource('Accept')}
                </Button>
              ) : (
                /*<Button type="disable">Accept</Button>*/ <div></div>
              )}
            </div>
          </div>
        </div>
      </Modal>


      <Modal title={<h3 className="component-title">{confirmationTitle}</h3>} centered visible={showModalConfirmation} footer={null} onCancel={onCloseConfirmation} maskClosable={false} zIndex={1010}>
        <div>
          <div>
            <h4>{confirmationMessage}</h4>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6">
              <div className="col">
                <Button type="primary" onClick={() => onOkConfirmation()}>
                  {getResource('Button.Ok')}
                </Button>
              </div>
            </div>
            <div className="col-xs-12 col-md-6">
              <div className="col">
                <Button type="primary" onClick={() => onCloseConfirmation()}>
                  {getResource('Button.Cancel')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default QuoteBox;
