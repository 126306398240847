import { Breadcrumb, Modal } from "antd";
import { getResource } from "../../helpers/ResourcesHelper";
import Button from "../inputs/Button";

const ConfirmationModal = (props: any) => {

  return <Modal
    title={
      <div className="breadcrumb-container">
        <Breadcrumb separator="">
          <Breadcrumb.Item >{props.mainTitle ? props.mainTitle : 'Important'}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
    }
    centered
    visible={props.visible}
    footer={null}
    onCancel={props.cancel}
    maskClosable={false}

    zIndex={1021}
    closable={false}
  >
    <div>
      <div>
        <h4>{props.title}</h4>
      </div>
      <div className="row">
        <div className="col-xs-12 col-md-6">
          <div className="col">
            <Button id="confirmation-afex-OK" type="primary" onClick={props.confirm}>
              {getResource('Yes')}
            </Button>
          </div>
        </div>
        <div className="col-xs-12 col-md-6">
          <div className="col">
            <Button id="confirmation-afex-cancel" type="primary" onClick={props.cancel}>
              {getResource('No')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  </Modal>
}

export default ConfirmationModal